.header__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 55px;
  align-items: center;
  background: #F7F7F7;
  padding: 15px 30px;
  border-style: solid;
  border-color: #e4e4e4;
  border-width: 0px 0px 1px 0px;
  margin: 0px;
  width:-webkit-fill-available;
  width: 100%;
  position: fixed;
  z-index:1100;
  top: 0;
  .logo {
    width: 80px;
    height: 30px;
    margin-bottom: 0px;
    font-size: 29px;
    font-weight: 700;
    align-items: center;
    display: flex;
    position: fixed;
    top: 15px;
  }
  .btn_wrapper {
    margin-left: auto;
    .btn {
      border-radius: 10px;
      border: 1px solid #c7c7c7;
      background-color: white;
      color: black;
    }
  }
}
@media (min-width:530px) {
  .header__wrapper {
    width: 500px;
  }
}