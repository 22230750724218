.footer__wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  color: white;
  text-align: center;
  width: -webkit-fill-available;
  max-width: 500px;
  width: 100%;
  margin: auto;
  left: auto;
  z-index: 100;
  padding-bottom: env(safe-area-inset-bottom, 20px);
  min-height: calc(56px + env(safe-area-inset-bottom, 20px));
  overflow-x: hidden;
  .col {
    padding-bottom: env(safe-area-inset-bottom, 20px);
    min-height: calc(56px + env(safe-area-inset-bottom, 20px));
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ededed;
    background: white;
    z-index: 1;
    color: #a6a6a6;
    font-size: 14px;
    letter-spacing: -0.17px;
    font-weight: 700;
    &:hover,  &.active{
      color: #000000;
    }
  }
}
@media (min-width: 530px) {
  .footer__wrapper {
    width: 500px;
  }
}