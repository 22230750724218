.my__page {
  margin-top: 60px;
  width: 100%;
  min-height: calc(100vh + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  .details {
    border: none;
    margin-bottom: 15px;
    .name {
      color: #141414;
      text-align: left;
      font-family: "NanumSquare", sans-serif;
      font-size: 16px;
      font-weight: 400;
    }
    .dob {
      color: #141414;
      text-align: left;
      font-family: "NanumSquare", sans-serif;
      font-size: 14px;
      line-height: 27.19px;
      font-weight: 400;
    }
    .login {
      background: #141414;
      border-radius: 8px;
      border-style: solid;
      border-color: #e4e4e4;
      border-width: 1px;
      padding: 4px 14px 4px 14px;
      width: 80px;
      height: 38px;
      color: white;
    }
    .default_text{
      font-size: 15px;
      width: 250px ;
      margin: 0px 5px 0px 5px;
    }
    .icon{
      margin: auto 20px auto 0px;
      color: #575757;
    }
  }
  .heading {
    font-family: "NanumSquare", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 27.19px;
    text-align: left;
    padding: 0rem 1rem;
    color: #878789;
  }
  .my-destiny-number {
    border: none;
    background-color: #f7f7f7;
    .card__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px;
      background-color: white;
      margin-bottom: 16px;
    }
    .fortune__card{ 
      width: 90px;
      height: 170px;
      .card-body{
        font-size: 24px;
      }
    }
  }
  .version-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;

    .version {
      color: #575757;
      text-align: left;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      margin-bottom: 0px;
    }
  }
  .business-info-wrapper{
    padding-left: 16px;
    color: #575757;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
    p{
      margin-bottom: 2px;
    }
    .bold{
      font-weight: 700;
    }
  }
  .terms {
    border: none;
    border-bottom: 1px solid #dbdee3;
    border-radius: 0px;
    cursor: pointer;
    h6 {
      font-family: "NanumSquare", sans-serif;
      color: #767676;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      margin-bottom: 0px;
    }
    .icon{
      color: #575757;
      margin-right: 20px;
      margin-bottom: 0px;
    }
  }
}
.gmarket-font {
  font-family: 'GmarketSans', sans-serif !important;
}
@media (max-width:390px) {
  .default_text{
    font-size: 14px !important;
  }
  // .my__page .my-destiny-number .fortune__card .card-body{
  //   font-size: 16px !important;
  // }
}