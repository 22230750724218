.edit-info-container {
  margin: 0 auto;
  background-color: #f7f7f7;
  padding: 10px 0px;
  width: 300px;

  form {
    width: 100%;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ddd;
  }

  .back-button {
    width: 20px;
    color: #575757;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    width: 18px;
    transform-origin: 0 0;
    background-color: transparent;
  }
  .complete-button {
    background-color: transparent;
    border: none;
    font-size: 18px;
    width: 70px;
    color: #878789;
    font-size: 14px;
    line-height: 27.19px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    margin-bottom: 0px;
    flex-grow: 1;
    text-align: center;
    font-weight: bold;
    color: #878789;
    text-align: left;
    font-size: 14px;
    line-height: 27.19px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
  }

  .info-section {
    width: 100%;
    background: #ffffff;
    border: none;
  }

  .info-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    align-items: center;
    height: 53px;
  }

  // .info-item:last-child {
  //   border-bottom: none;
  // }

  .birthday-values {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0px;
    }
    .react-datepicker__input-container {
      right: -33px;
    }
  }

  p {
    color: #575757;
    text-align: left;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0px;
  }

  .form-control{
    text-align: right !important;
    width: 80%;
    max-width: 175px;
    border: none;
    color: #575757;
  }
  .form-select{
    width: 80px !important;
    margin: 0px 2px;
    border: 1px solid #d6d6d6;
    color: #575757;
  }
  .form-select, .form-control {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &:focus {
      border-color: #000000;
      box-shadow: 0 0 0 0.04rem rgb(0 0 0 / 91%);
    }
  }
  @media (max-width: 375px) {
    .edit-info-container {
      padding: 5px;
    }
  }
}
.birthType.form-select{
  margin-left: 10px;
}