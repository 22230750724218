.tarot-grid-container {
	display: flex;
	flex-direction: column;
	width: 400px;
	cursor: pointer;
	.tarot-card {
		height: 175px;
		width: auto;
		box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
		transition: transform 0.3s ease, box-shadow 0.3s ease;
		animation: fade-in 0.6s ease;
	  }
	  .tarot-card:hover {
		transform: scale(1.05);
		box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
		background: radial-gradient(
		  circle at var(--mouse-x) var(--mouse-y),
		  rgba(255, 255, 255, 0.1),
		  transparent 40%
		);
	  }
	  .tarot-card::before {
		content: '';
		position: absolute;
		top: -50%;
		left: -50%;
		width: auto;
		height: 135px;
		background: rgba(255, 255, 255, 0.623);
		pointer-events: none;
		border-radius: 50%;
		animation: cardrotate 6s linear infinite;
	  }
}
.fortune-by-year-content {
	.tarot-grid-container {
		width: 200px;
		justify-content: center;
		align-items: center;
		height: 100px;
		.tarot-card {
			height: 125px;
			width: auto;
		}
	}
}
.fortune-by-year-slider {
	.tarot-grid-container {
		display: flex;
		flex-direction: column;
		width: 300px;
		cursor: pointer;
		.tarot-card {
			height: 130px;
			width: auto;
			animation: none;
		}
		.tarot-card::before{
			animation: none;
		}
	}
}

.fortune-by-years-content_card {
	display: flex;
	flex-direction: column;
	.message__wrapper{
		margin-bottom: 0rem!important;
		.message-content {
			align-items: center;
			margin-top: 20px;
			.message{
				font-size: 16px!important;
				line-height: 25px !important;
				margin-bottom: 10px;
			}	
			.btn{
				margin-top: 25px;
				font-size: 14px!important;
			}
		}
	}
	.tarot-grid-container {
		width: 300px;
		display: flex;
		flex-direction: column;
		height: 200px;
		.tarot-card {
			height: 130px;
			width: auto;
		}
	}
	@keyframes cardrotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
	}

	@keyframes fade-in {
	from {
		opacity: 0;
		transform: scale(0.8);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
	}
}

.box-content {
	background: #f9f9f9;
	background: -moz-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	background: -webkit-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	background: -o-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	background: -ms-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	background: linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f9f9f9',GradientType=0 );
	-webkit-box-shadow: 0 1px 0 #fff inset;
	-moz-box-shadow: 0 1px 0 #fff inset;
	-ms-box-shadow: 0 1px 0 #fff inset;
	-o-box-shadow: 0 1px 0 #fff inset;
	box-shadow: 0 1px 0 #fff inset;
	border: 1px solid #c4c6ca;
	margin: 0 auto;
	padding: 5px 10px;
	position: relative;
	text-align: left;
	text-shadow: 0 1px 0 #fff;
	width: 430px;
	font-size: 13px;
	line-height: 22px;
	letter-spacing: -0.03em;
	font-weight: 400;
	ul {
		padding: 18px 0px 18px 10px;
		li {
			font-size: 13px;
			line-height: 22px;
			letter-spacing: -0.03em;
			font-weight: 400;
		}
	}
}
.box-content:after,
.box-content:before {
	background: #f9f9f9;
	background: -moz-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	background: -webkit-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	background: -o-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	background: -ms-linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	background: linear-gradient(top, rgba(248, 248, 248, 1) 0%, rgba(249, 249, 249, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f8f8', endColorstr='#f9f9f9',GradientType=0 );
	border: 1px solid #c4c6ca;
	content: "";
	display: block;
	height: 100%;
	left: -1px;
	position: absolute;
	width: 100%;
}
.box-content:after {
	-webkit-transform: rotate(2deg);
	-moz-transform: rotate(2deg);
	-ms-transform: rotate(2deg);
	-o-transform: rotate(2deg);
	transform: rotate(2deg);
	top: 0;
	z-index: -1;
}
.box-content:before {
	-webkit-transform: rotate(-3deg);
	-moz-transform: rotate(-3deg);
	-ms-transform: rotate(-3deg);
	-o-transform: rotate(-3deg);
	transform: rotate(-3deg);
	top: 0;
	z-index: -2;
}

.fortune-by-year-slider {
	.slide__wrapper {
		justify-content: center !important;
		align-items: center !important;
	}
}

.description-wrapper .description p{
	width: auto;
}

@media (max-width: 1400px) {
	.box-content {
		width: 380px;
	}
}

@media (max-width: 992px) {
	.box-content {
		width: 100%;
		max-width: 380px;
	}
}

@media (max-width: 768px) {
	.box-content {
		width: 100%;
		max-width: 380px;
	}
}

@media (max-width: 480px) {
	.box-content {
		width: 100%;
		max-width:320px;
	}
}
@media (max-width: 390px) {
	.box-content {
		width: 100%;
		max-width:300px;
	}
}
