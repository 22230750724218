.content {
  .start__wrapper div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    .logo {
      width: auto;
      height: 80px;
    }
    .btn {
      background: #141414;
      border-radius: 3px;
      width: auto;
      height: 50px;
    }
  }
  .pre__start__wrapper > p:nth-child(3) {
    margin-bottom:7rem;
  }
  .startPage_2025{
    width: 100%; /* 부모의 너비에 맞춤 */
    max-width: 500px; /* 최대 너비 제한 */
    // height: 100%;
    aspect-ratio: 1 / 2; /* 반응형 비율 설정 (2:1) */
    background-image: url('../../images/onbording_v1.svg');
    background-repeat: no-repeat; /* 배경 반복 없음 */
    background-size: cover; /* 배경을 비율에 맞춰 채움 */
    background-position: center; /* 중앙에 위치 */
    margin: 0 auto; /* 중앙 정렬 */
    display: flex; /* 내부 콘텐츠 정렬 */
    flex-direction: column; /* 내부 요소를 세로 정렬 */
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .btn_2025{
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    border: #2D2320 2px solid!important;
    color: #2D2320 !important;
    cursor: pointer;
    padding: 10px;
    position: relative;
    z-index: 1;
    font-size: 16px!important;
    font-weight: 700;
    transition: left 0.3s ease-in-out;
    background-color: #E9D3C1;
    height: 50px !important;
    width: 240px;
    margin-top: 250px;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .hidden {
    opacity: 0;
  }
  
  .fade-in {
    opacity: 1;
    animation: fadeIn 0.7s forwards;
  }
}
