.landing__page {
  padding-bottom: 70px !important;
  background: #f7f7f7;
  padding: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  .content-section {
    height: 660px;
    min-width: 332px;
    padding: 20px;
    background-color: #f7f7f7;
    // margin: 10px 0px 30px 0px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-origin: border-box, border-box !important;
    background-position: center, center !important;
    background-repeat: no-repeat, no-repeat !important;
    background-color: rgba(0, 0, 0, 0);
    background-size: contain, contain;
    width: 400px;
    height: 626px;
  }
  .content-section h2 {
    margin-bottom: 10px;
    color: #575757;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .content-section p {
    font-size: 14px;
    color: #333;
  }
  .redirect-test--wrapper{
    margin-bottom: 0px;
  }
  .paper-card-bg {
    background-image: url("../../../assets/images/card-bg.svg");
    background-size: cover;
    height: 320px;
    width: 100%;
    margin-top: 10px;
  }
  .paper-card {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    height: 100%;
    margin-top: 17%;
    margin-left: 10%;
    p {
      color: #000000;
      text-align: left;
      font-size: 13px;
      letter-spacing: -0.07em;
      font-weight: 500;
      position: relative;
      line-height: 1.4rem;
    }
  }
  .questions{
    background-image: url("../../../assets/images/questions-bg.svg");
    background-size: cover;
    height: 340px;
    width: 380px;
  }
  .more-reviews-button,
  .start-test-button {
    background-color: #199e59;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }

  .more-reviews-button:hover,
  .start-test-button:hover {
    background-color: #148e4f;
  }

  .header {
    color: #575757;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    .bold {
      font-weight: 700;
    }
  }
  .header-white{
    color: #ffffff;
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 4rem;
    width: 223px;
    line-height: 1.7rem;
  }
  .subheader {
    color: #000000;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    letter-spacing: -0.05em;
  }
  .carousel-section {
    // margin-top: 10px !important;
    // margin-bottom: 10px !important;
    display: flex;
    justify-content: center;
    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border-radius: 20px;
      width: 220px;
      height: auto;
      position: relative;
    }
    .card p {
      margin: 0;
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .btn_bg4 {
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    border: #867a6b 2px solid!important;
    color: #867a6b !important;
    cursor: pointer;
    padding: 10px;
    position: relative;
    z-index: 1;
    font-size: 13.5px;
    font-weight: 700;
    transition: left 0.3s ease-in-out;
    background-color: #ffeddb;
    height: 45px !important;
    margin-bottom: 30px;
  }
}
@media (max-width:430px) {
  .landing__page .questions{
    width: 315px;
    height: 280px;
  }
  .landing__page .content-section{
    width: 315px;
    height: 493px;
    padding: 15px;
    margin: 10px 0px 15px 0px;
  }
  .landing__page .questions-wrapper{
    padding-bottom: 15px;
  }
  .landing__page .content-section h6 {
    font-size: 16px;
  }
  .landing__page .paper-card-bg{
    height: 290px;
  }
  .landing__page .paper-card p{
    font-size: 12.5px;
    margin-bottom: 8px;
    line-height: 1.3rem;
  }
  .landing__page .subheader{
    margin-bottom:10px;
  }
  .landing__page .carousel-section{
    // margin-top: 10px !important;
    width: 100% !important;
  }
}