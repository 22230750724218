.test__wrapper {
  margin-bottom: 5rem;
  margin-top: 80px;
  overflow: scroll;
  scrollbar-width: none;
  width: 80%;
}

.test-card {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.5);
  border-style: solid;
  border-color: #d6d6d6;
  border-width: 1px;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin: 0px auto;
  border-bottom: none;
  width: 100%;
  &:last-child {
    border-bottom: 1px solid #d6d6d6;
  }
  &:hover, &:active {
    background: #2b2b2c;
    h3, p {
      color: white;
    }
  }
  .icon {
    color: #000000;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    width: 25px;
    margin-left: 10px;
  }
}

.test-info {
  flex-grow: 1;
}

.test-info h3 {
  color: #000000;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.test-info p {
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  max-width: 330px;
  margin-bottom: 0px;
}
.test-info .test_new_badge{
  color: rgb(255, 255, 255);
  background-color: #f3775b;
  border: 1px solid rgb(255, 255, 255);
  text-align: left;
  font-size: 11px;
  font-weight: 400;
  width: fit-content;
  height: 18px;
  padding: 3px 3px 4px 3px;
  margin-right: 8px;
  border-radius: 5px;
}
.test-info .test_comingsoon_badge{
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border: 1px solid black;
  text-align: left;
  font-size: 11px;
  font-weight: 400;
  width: fit-content;
  height: 18px;
  padding: 3px 3px 4px 3px;
  margin-right: 8px;
  border-radius: 5px;
}
@media (max-width:390px) {
  .test-info h3{
    font-size: 14px;
  }
  .test-card {
    padding: 10px 15px;
  }
}