.content{
  display: flex;
  flex-direction: column;
  align-items: center;
  .auth_wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    width: 300px;
    .logo {
      width: 130px;
      height: 70px;
      margin-top: 20px;
      margin-bottom: 50px;
      font-size: 32px;
      font-weight: 700;
    }
    .form-control {
      border-radius: 3px;
      background: white;
      margin-bottom: 0px;
      height: 48px;
      border: 1px solid #d6d6d6;
      margin-bottom: 1rem;
      &:focus {
        border-color: $theme-base-color-1;
        box-shadow: 0 0 0 0.25rem #5dd9b903;
      }
    }
  
    form {
      width: 90%;
      .btn__wrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        width: -webkit-fill-available;
        justify-content: center;
        align-items: center;
        .btn {
          margin-bottom: 10px;
          height: 42px;
          width: 100%;
          font-size: 15px;
        }
      }
      a {
        padding: 1rem;
        font-size: 15px;
      }
    }
  }  
}
.social-login-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0px;
  border-radius: 10px;
  width: 240px;
  margin: 0 auto;
}

.login-text {
  color: #575757;
  text-align: left;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 50px;
  padding: 10px 18px 10px 18px;
  display: flex;
  width: 185px;
  height: 40px;
  border: none;
  color: rgba(0, 0, 0, 0.85);
  text-align: left;
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
}

button span{
  padding: 10px 18px;
}

button svg {
  margin-right: 8px;
}

.kakao-login {
  background-color: #fee500;
  color: #3c1e1e;
  font-size: 13px;
}

.google-login {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ccc;
  font-size: 13px;
}

.naver-login {
  background-color: #03c75a;
  color: #fff;
}

button:hover {
  opacity: 0.9;
}
