.terms__page{
  padding: 30px 20px;
  background: white;
  .header{
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    h6{
      font-size: 18px;
    font-weight: 400;
    line-height: 27.19px;
    text-align: center;
    }
    
  }
  h5{
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: black;
  }
  h6{
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: black;
  }
  p{
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
}