// =======================================
//     List of variables for layout
// =======================================
/*
*/
// Colors
$theme-base-color-1: #141414;
$theme-base-color-2: #141414;
$theme-base-color-3: #141414;
$theme-base-color-4: #141414;
$theme-background-color: #F7F8FA;
$base-linear-gradient: #141414;
$border-linear-gradient : #141414;
$text-linear-gradient : linear-gradient(89deg, #fe646ebd 5%, #FFD15Bbd 20%, #00C27Abd 50%,#01D0FBbd 75%,#BD80E1bd 95% );
$text-linear-gradient-2 : linear-gradient(89deg, #fe646e95 5%, #FFD15B95 20%, #00C27A95 50%,#01D0FB95 75%,#BD80E195 95% );
$text-color: #333;
$text-color-2: #676767;
// Fonts
$font-stack: 'GmarketSans', sans-serif;
$heading-font:  'GmarketSans', sans-serif;
$logo-font: 'GmarketSans', sans-serif;

// Breakpoints
$mobile-breakpoint: 600px;
$screen-max-width: 440px;