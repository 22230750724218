.content{
  display: flex;
  flex-direction: column;
  align-items: center;
.user_wrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  .description {
    color: #767676;
    text-align: left;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: -0.1em;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-image: url("../../../assets/icons/user-shape.svg");
    // width: 100%;
    padding: 0 0 0 2.5rem;
    background-size: 220px;
    background-position: 20% 40%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    height: 120px;
    margin: 0px 20px 20px 0px;
    .logo {
      width: 80px;
      height: 30px;
      margin-bottom: 0px;
      font-size: 29px;
      font-weight: 700;
      margin-top: 8px;
    }
  }

  .form-control {
    border-radius: 3px;
    background: white;
    margin-bottom: 20px;
    height: 48px;
    &:focus {
      border-color: $theme-base-color-1;
      box-shadow: 0 0 0 0.25rem #5dd9b903;
    }
  }

  form {
    width: 80%;
    position: relative;
    .date__wrapper {
      display: flex;
      flex-direction: row;
      input {
        margin-right: 0px;
        height: 44px;
      }
      .toggle-switch {
        width: 190px;
        margin-left: 10px;
        .btn{
          font-size: 14px;
        }
      }
    }

    .switch__wrapper {
      .toggle-switch {
        width: 100%;
        .btn{
          font-size: 14px;
        }
      }
    }

    .btn__wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;
      justify-content: center;
      align-items: center;
      .btn {
        margin-bottom: 150px;
        height: 42px;
        width: 100%;
        font-size: 14px;
      }
    }

    .helper-text {
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #8191a7;
      margin-bottom: 5px;
    }

    .checkbox label {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #767676;
    }
    .checkbox input {
      -webkit-appearance: none;
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      background: #f8f7f7;
      border: none;
      cursor: pointer;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
    .checkbox input:checked {
      background: $base-linear-gradient;
    }

    .checkbox input::before {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
      background-size: contain;
      background-repeat: no-repeat;
    }

    .form-check-label {
      letter-spacing:-0.5px;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #767676;
      text-decoration: underline;
      margin-left: 5px;
      cursor: pointer;
    }
    .form-check-input {
      -webkit-appearance: none;
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      background: #f8f7f7;
      border: none;
      cursor: pointer;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
    .form-check-input:checked {
      background: $base-linear-gradient;
    }

    .form-check-input::before {
      content: "";
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
      background-size: contain;
      background-repeat: no-repeat;
    }
    .form-control{
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #8191a7;
      margin-bottom: 15px;
    }
    input {
      appearance: none;
      -webkit-appearance: none; /* For Safari */
      -moz-appearance: none; /* For Firefox */
      position: relative; /* 필수는 아니지만, 말풍선 위치를 개선할 수 있음 */
    }
  }
}
}