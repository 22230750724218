.content{
  display: flex;
  flex-direction: column;
  align-items: center;
.auth_wrapper {
  width: 85%;
  margin-top: 80px;
  .description {
    color: #767676;
    text-align: left;
    font-size: 20px;
    line-height: 35px;
    letter-spacing: -0.03em;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-image: url("../../../assets/icons/signup-shape.svg");
    // width: 65%;
    padding: 0px 2.5rem;
    background-size: 225px;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    height: 200px;
    margin: 0px auto 0px 0px;
    .logo {
      width: 80px;
      height: 30px;
      margin-bottom: 0px;
      margin-top: 8px;
      margin-right: 5px;
      font-size: 29px;
    }
  }

  form {
    width: 80%;
    position: relative;
    .helper-text {
      font-size: 10.5px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #8191a7;
      margin-bottom: 15px;
    }
    .form-control{
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: #8191a7;
      margin-bottom: 5px; 
      margin-top: 15px; 
    }
    .small .text-muted .form-text{
      font-size: 13px;
    }
    input {
      appearance: none;
      -webkit-appearance: none; /* For Safari */
      -moz-appearance: none; /* For Firefox */
      position: relative; /* 필수는 아니지만, 말풍선 위치를 개선할 수 있음 */
    }
  }
}
}