.daily_fortune {
	.back-icon {
		display: flex;
		justify-content: end;
		width: 100%;
		margin-top: 1.5rem;
		cursor: pointer;
		margin-right: 1rem;
	}
	.card__container {
		.daily-fortune-content {
			width: 100%;
			height: 85vh;
			display: flex;
			justify-content: center;
			align-content: center;
			margin-bottom: 1rem;
			.card_wrapper {
				margin-top: -25px;
				.rect-card {
					cursor: pointer;
					background-position: center;
					display: flex;
					justify-content: center;
					align-items: center;
					background-repeat: no-repeat;
					height: 100px;
					padding: 0px 10px;
					p {
						line-height: 22px;
						letter-spacing: -0.03em;
						font-weight: 500;
						width: 90%;
						margin-bottom: 0px;
						text-align: left;
						.title {
							font-family: "GmarketSans", sans-serif;
							font-size: 13.5px;
						}
						.content {
							font-size: 12.5px;
							line-height: 18px;
							letter-spacing: -0.03em;
							font-weight: 400;
						}
					}
				}
				.section_row {
					margin-left: 5px;
					margin-right: 5px;
					font-size: 14px;
					.align-left {
						display: flex;
						align-items: center;
						justify-content: start;
					}
					.align-right {
						display: flex;
						align-items: center;
						justify-content: end;
					}
					.rightIcon {
						width: 13px;
					}
					.title {
						font-family: "GmarketSans", sans-serif;
						font-weight: 400;
					}
					.square-card {
						cursor: pointer;
						background-position: center;
						display: flex;
						justify-content: center;
						align-items: center;
						background-repeat: no-repeat;
						height: 85px;
						width: 150px;
						line-height: 22px;
						font-weight: 400;
						padding: 0px 10px;
						letter-spacing: -0.05em;
						font-size: 13.5px;
						svg {
							margin-left: auto;
							width: 17.45px;
							height: 20.82px;
						}
						.rightIcon {
							width: 13px;
						}
						.title {
							font-family: "GmarketSans", sans-serif;
						}
					}
				}
			}
		}
	}

	.tarot-grid-container {
		display: flex;
		flex-direction: column;
		width: fit-content;
		max-width: 400px;
		cursor: pointer;
		.tarot-card {
			height: 120px !important;
			width: auto;
			box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
			transition:
				transform 0.3s ease,
				box-shadow 0.3s ease;
			animation: fade-in 0.6s ease;
		}
		.tarot-card:hover {
			transform: scale(1.05);
			box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
			background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.1), transparent 40%);
		}
		.tarot-card::before {
			content: "";
			position: absolute;
			top: -50%;
			left: -50%;
			width: auto;
			height: 120px !important;
			background: rgba(255, 255, 255, 0.623);
			pointer-events: none;
			border-radius: 50%;
			animation: cardrotate 6s linear infinite;
		}
	}
}
.daily-fortune-slider {
	.tarot-grid-container {
		width: 300px;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 160px !important;
		.tarot-card {
			height: 130px !important;
			width: auto;
		}
	}
}
.daily-fortune-do-dont-description {
	width: 65%;
	margin-top: 2.5rem;
	.title {
		margin-bottom: 1rem;
		.box-content {
			padding: 10px;
			background: white;
			font-size: 13px;
			line-height: 22px;
			letter-spacing: -0.04em;
			font-weight: 700;
			text-align: center;
			border: 1px solid #a1a2a5;
		}
		.box-content:before {
			transform: rotate(-1deg);
		}
		.box-content:after {
			transform: rotate(1.5deg);
		}
	}
	.description-wrapper {
		width: 96%;
		margin: 1rem auto auto auto;
		.description {
			max-width: 330px;
			margin-bottom: 5px;
			.box-content {
				padding: 10px;
				background: white;
				font-size: 13px;
				line-height: 22px;
				letter-spacing: -0.03em;
				font-weight: 400;
				text-align: center;
			}
			.box-content:before {
				transform: rotate(-1deg);
			}
			.box-content:after {
				transform: rotate(1.5deg);
			}
		}
	}
}
.daily-fortune-description {
	margin-top: 2.5rem;
	.description {
		margin-bottom: 5px;
		h6 {
			font-size: 13px;
			line-height: 22px;
			letter-spacing: -0.03em;
			font-weight: 500;
			margin-bottom: 0px;
		}
		ul li {
			font-size: 12.5px;
			line-height: 22px;
			letter-spacing: -0.03em;
			font-weight: 400;
		}
		p {
			font-size: 12.5px;
			line-height: 22px;
			letter-spacing: -0.03em;
			font-weight: 400;
		}
		.box-content {
			padding: 10px;
			background: white;
			max-width: 350px;
		}
		.box-content:before {
			transform: rotate(-1deg);
			top: 0;
			z-index: -2;
		}
		.box-content:after {
			transform: rotate(1.5deg);
			top: 0;
			z-index: -1;
		}
	}
}
.daily-fortune-slider .slide__wrapper {
	margin-top: 1rem !important;
	.back-icon {
		display: flex;
		justify-content: end;
		width: 100%;
		margin-top: 1rem;
		cursor: pointer;
		margin-right: 1rem;
	}
	.box-content {
		width: auto !important;
	}
	.tarot-grid-container {
		padding-top: 30px;
	}
}
.daily-fortunes-content_card {
	justify-content: flex-start;
	display: flex;
	flex-direction: column;
	.message__wrapper {
		margin-top: 2rem;
		margin-bottom: 0rem !important;
		width: 75%;
		.message-content {
			align-items: center;
			margin-top: 20px;
			.message {
				font-size: 16px !important;
				line-height: 25px !important;
				margin-bottom: 10px;
			}
			.btn {
				margin-top: 25px;
				font-size: 14px !important;
			}
		}
	}
	.tarot-grid-container {
		width: 300px;
		display: flex;
		flex-direction: column;
		height: 200px !important;
		.tarot-card {
			height: 120px !important;
			width: auto;
		}
	}
	@keyframes cardrotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes fade-in {
		from {
			opacity: 0;
			transform: scale(0.8);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}
}
@media (min-width: 1200px) {
	.rect-card {
		p {
			width: 60% !important;
		}
	}
}
@media (max-width: 1200px) {
	.daily-fortune-description .description .box-content {
		width: 380px;
	}
	.rect-card {
		p {
			width: 60% !important;
		}
	}
}

@media (max-width: 992px) {
	.daily-fortune-description .description .box-content {
		width: 100%;
		max-width: 380px;
	}
	.daily-fortune-slider{
		.tarot-grid-container {
			width: 200px;
			height: 240px!important;
			padding-top: auto!important;
			.tarot-card {
				height: 120px!important;
				width: auto;
			}
		}
	}
	.description-wrapper .daily-fortune-do-dont-description{
		margin-top:1rem!important;
	}
	.rect-card {
		p {
			width: 60%;
		}
	}
}

@media (max-width: 768px) {
	.daily-fortune-description .description .box-content {
		width: 100%;
		max-width: 380px;
	}
	.daily-fortune-slider{
		.tarot-grid-container {
			width: 200px;
			height: 240px!important;
			padding-top: auto!important;
			.tarot-card {
				height: 120px!important;
				width: auto;
			}
		}
	}
	.description-wrapper .daily-fortune-do-dont-description{
		margin-top:1rem!important;
	}
	.rect-card {
		p {
			width: 60%;
		}
	}
}

@media (max-width: 480px) {
	.daily-fortune-description .description .box-content {
		width: 100%;
		max-width: 320px;
	}
	.rect-card {
		p {
			width: 80% !important;
		}
	}
	.daily-fortune-slider{
		.tarot-grid-container {
			width: 200px;
			height: 240px!important;
			padding-top: auto!important;
			.tarot-card {
				height: 120px!important;
				width: auto;
			}
		}
	}
	.description-wrapper .daily-fortune-do-dont-description{
		margin-top:1.5rem!important;
	}
}
@media (max-width: 390px) {
	.daily-fortune-description .description .box-content {
		width: 100%;
		max-width: 280px;
	}
	.daily_fortune .back-icon {
		padding-right: 1rem;
	}	
	.daily-fortune-slider{
		.tarot-grid-container {
			width: 200px;
			height: 220px!important;
			padding-top: auto!important;
			.tarot-card {
				height: 110px!important;
				width: auto;
			}
		}
	}
	.daily-fortune-slider .daily-fortune-do-dont-description{
		margin-top:0.5rem!important;
	}
}
