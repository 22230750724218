.content_card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  min-height: 100vh;
  .card__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0rem;
    margin-bottom: 2rem;
    z-index: 1;
  }
  p {
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    .bold {
      font-weight: 700;
    }
  }
  .btn {
    width: 80%;
    margin-top: 2rem;
  }
}
.fortune__card {
  cursor: pointer;
  margin: 8px;
  background: #f5f5f5;
  width: 130px;
  height: 210px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  background-image: url("../../../assets/icons/card_frame.png");
  background-position: center;
  background-size: cover;
  border: none;
  .card-body {
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: "Cormorant Garamond", serif;
    font-size: 32px;
    margin-bottom: 1rem;
  }
}
.bg-solar {
  background: linear-gradient(324deg, #eed484 19%, #f4e8c5 77%) !important;
}
.bg-lunar {
  background: linear-gradient(324deg, #d1efff 19%, #b1ddf4 77%) !important;
}

.bg-grey {
  background: #f5f5f5;
}

.fortune__card__container {
  display: flex;
  justify-content: center;
  min-height: calc(100vh + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);
  .slider-container {
    width: 530px;
    .slide__wrapper {
      height: 90vh;
      display: flex !important;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 3rem;
      align-items: center;
      overflow-y: scroll;
      scrollbar-width: none;
      margin-bottom: 2rem;
      .message__wrapper {
        margin-bottom: 7rem;
        .card {
          width: 100px;
          height: 165px;
        }
        .message-content {
          display: flex;
          flex-direction: column;
          .card__container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 20px;
            .fortune__card .card-body {
              font-size: 26px;
              margin-top: 10px;
            }
          }
          .message {
            color: #575757;
            text-align: center;
            font-size: 18px;
            line-height: 40px;
            font-weight: 400;
            width: 100%;
            .bold {
              font-weight: 700;
            }
          }
          .button-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        .close {
          position: absolute;
          top: 0;
          right: 0;
          padding: 20px 20px 0px 0px;
          img {
            width: 18px;
            height: auto;
          }
        }
        .logo {
          width: 80px;
          height: 30px;
          margin-bottom: 0px;
          font-size: 29px;
          font-weight: 700;
          margin-top: 8px;
        }
      }
      .single__card__wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        .fortune__card {
          width: 85px;
          height: 140px;
          .card-body {
            font-family: "Cormorant Garamond", serif;
          }
        }
      }
      .single__card__wrapper_keyword {
        width: 100%;
        display: flex;
        justify-content: center;
        .fortune__card {
          width: 135px;
          height: 215px;
          .card-body {
            font-family: "Cormorant Garamond", serif;
          }
        }
      }
      .heading {
        color: #575757;
        text-align: center;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.03em;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 10px 20px 10px;
      }
      .keywords__wrapper {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 1rem;
        .btn {
          margin: 5px;
        }
      }
      .message {
        width: 80%;
        margin: auto;
        font-size: 20px;
        font-weight: 400;
        line-height: 40px;
        text-align: left;
      }
      .vector {
        width: 100%;
        height: 100%;
        overflow: visible;
      }
      .description-wrapper {
        position: relative;
        .description {
          margin-bottom: 5px;
          width: 360px;
          .bg-box {
            background-position: center;
            background-size: 360px;
            max-width: 360px;
            width: 100%;
            height: 68px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-repeat: no-repeat;
          }
          p {
            color: #000000;
            text-align: left;
            font-size: 13px;
            line-height: 22px;
            letter-spacing: -0.03em;
            font-weight: 400;
            padding: 5px 8px;
            margin-bottom: 0px;
            width: 100%;
          }
        }
      }
      .border-gradient {
        position: relative;
        z-index: 1;
        background: $base-linear-gradient;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .border-gradient::before {
        content: "";
        display: block;
        /* We puth the actual background color for the contents here */
        background-color: white;
        position: absolute;
        /* We need to subtract a percentage equivalent to the amount used for top and left, since this curve is proportionally a little bit more closed than the outer one */
        border-radius: calc(10px - 2%);
        height: 98.5%;
        width: 98.5%;
        z-index: -1;
      }
    }
  }
}
.fortune__card__container .slider-container .slide__wrapper .single__card__wrapper .fortune__card .card-body {
  margin-top: 7px;
  font-size: 22px;
}
// .content {
//   display: flex;
//   align-items: center;
//   .cards__wrapper {
//     display: flex;
//     justify-content: center;
//     margin-bottom: 3rem;
//     .fortune__card {
//       margin: 8px;
//       height: 200px;
//       width: 125px;
//       .card-body {
//         font-family: "Cormorant Garamond", serif;
//       }
//     }
//   }
// }

.fortune-cards {
  width: 100% !important;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  margin-bottom: 1rem;
  background-color: white;
  .tarot-card {
    height: 140px !important;
  }
}
.content_card {
  .cards__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    z-index: 1; // 배경 위에 카드가 나타나도록

    .fortune__card {
      margin: 8px;
      height: 200px;
      width: 125px;

      .card-body {
        font-family: "Cormorant Garamond", serif;
      }
    }
  }
  .ani_card {
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }

  .ani_card:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.1), transparent 40%);
  }

  .ani_card::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 400px;
    height: 400px;
    background: rgba(255, 255, 255, 0.623);
    pointer-events: none;
    border-radius: 50%;
    animation: rotate 6s linear infinite;
  }

  @keyframes cardrotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1; // 배경이 카드 뒤에 있도록 설정
    li {
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      animation: animate 25s linear infinite;
      bottom: -150px;
      z-index: -1;
      &:nth-child(1) {
        left: 25%;
        width: 80px;
        height: 80px;
        animation-delay: 0s;
      }

      &:nth-child(2) {
        left: 10%;
        width: 20px;
        height: 20px;
        animation-delay: 2s;
        animation-duration: 12s;
      }

      &:nth-child(3) {
        left: 70%;
        width: 20px;
        height: 20px;
        animation-delay: 4s;
      }

      &:nth-child(4) {
        left: 40%;
        width: 60px;
        height: 60px;
        animation-delay: 0s;
        animation-duration: 18s;
      }

      &:nth-child(5) {
        left: 65%;
        width: 20px;
        height: 20px;
        animation-delay: 0s;
      }

      &:nth-child(6) {
        left: 75%;
        width: 110px;
        height: 110px;
        animation-delay: 3s;
      }

      &:nth-child(7) {
        left: 35%;
        width: 150px;
        height: 150px;
        animation-delay: 7s;
      }

      &:nth-child(8) {
        left: 50%;
        width: 25px;
        height: 25px;
        animation-delay: 15s;
        animation-duration: 45s;
      }

      &:nth-child(9) {
        left: 20%;
        width: 15px;
        height: 15px;
        animation-delay: 2s;
        animation-duration: 35s;
      }

      &:nth-child(10) {
        left: 85%;
        width: 100px;
        height: 100px;
        animation-delay: 0s;
        animation-duration: 11s;
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

// @keyframes float {
//   0% {
//     transform: translateY(0) rotate(0deg);
//   }
//   50% {
//     transform: translateY(-20px) rotate(180deg);
//   }
//   100% {
//     transform: translateY(0) rotate(360deg);
//   }
// }

/* Background circles start */
.circle {
  position: absolute;
  border-radius: 50%;
}
.circle-1 {
  height: 110px;
  width: 110px;
  top: -55px;
  left: -60px;
  opacity: 0.8;
  background: linear-gradient(315deg, #ead185 19%, #ffbb00 77%);
}
.circle-2 {
  height: 130px;
  width: 130px;
  bottom: -70px;
  right: -60px;
  opacity: 0.8;
  background: linear-gradient(125deg, #2cbefd 19%, #0a9dfd 77%);
}
/* Background circles end */

.svg-cap {
  display: inline-block;
}

.left {
  margin-right: 10px; /* Space between the SVG and content */
  position: absolute;
  left: -17px;
  top: 30px;
  z-index: -1;
}

.right {
  margin-left: 10px;
  position: absolute;
  right: -17px;
  top: 30px;
  z-index: -1;
}

.comments__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;
  h5 {
    color: #575757;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    text-align: center;
    line-height: 2rem;
  }
  textarea {
    // background: #ffffff;
    border-radius: 8px;
    border: 0.7px solid rgba(133, 133, 133, 0.5);
    width: 300px;
    height: 120px;
    text-align: center;
    font-size: 16px;
  }
}
.ratings__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;

  h5 {
    color: #575757;
    font-size: 20px;
    line-height: 40px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 3rem;
  }

  .card-container {
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .card {
    cursor: pointer;
    background: #ffffff;
    border-radius: 8px;
    border-style: solid;
    border-color: rgba(133, 133, 133, 0.5);
    border-width: 0.5px;
    margin: 0 10px 30px;
    padding: 13px 0px;
    width: 70px;
    height: 115px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 44px;
      height: 44px;
    }
    h6 {
      color: rgba(118, 118, 118, 0.8);
      text-align: center;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: -0.08em;
      font-weight: 400;
    }
    &:hover {
      border: 1.5px solid #2b2b2c;
      h6,
      p {
        color: #2b2b2c;
      }
    }
  }
  .active {
    border: 1.5px solid #2b2b2c;
    h6,
    p {
      color: #2b2b2c;
    }
  }
}
.date-dropdown {
  .dropdown-menu {
    height: 250px;
    overflow: scroll;
  }
  .dropdown-item {
    padding: 8px 24px;
    &:hover,
    &:active {
      color: white;
      background: #141414;
      border-color: #141414;
    }
  }
  .active {
    color: white;
    background: #141414;
    border-color: #141414;
  }
  #select {
    font-size: 14px;
    width: 90px;
    margin-left: 0px;
    height: 40px;
    text-align: center;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
  }
  #selectYear::after {
    margin-left: 0.955em !important;
  }
}
@media (max-width: 390px) {
  .fortune__card__container .slider-container .slide__wrapper .description-wrapper {
    .description {
      margin-bottom: 0px !important;
      .bg-box {
        background-size: 285px !important;
        width: 285px !important;
      }
      p {
        width: 285px;
      }
    }
  }
  .fortune__card__container .slider-container {
    width: 325px !important;
  }
  .slick-next,
  .slick-prev {
    svg {
      width: 18px !important;
      height: auto !important;
    }
  }

  .comments__wrapper textarea {
    width: 230px;
  }
  .slick-prev {
    left: -5px !important;
  }
  .slick-next {
    right: -5px !important;
  }
}
@media (max-width: 530px) {
  .fortune__card__container .slider-container .slide__wrapper .description-wrapper {
    .description {
      margin-bottom: 3px;
      width: 100%;
      .bg-box {
        background-size: 330px;
        width: 330px;
        height: 62px;
      }
      p {
        color: #000000;
        text-align: left;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: -0.03em;
        font-weight: 400;
        margin-bottom: 0px;
        width: 100%;
        padding: 3px 5px;
      }
    }
  }
  .fortune__card__container .slider-container {
    width: 370px;
  }
  .slick-next,
  .slick-prev {
    svg {
      width: 18px !important;
      height: auto !important;
    }
  }
  .slick-prev {
    left: -5px !important;
  }
  .slick-next {
    right: -5px !important;
  }
  .comments__wrapper textarea {
    width: 270px;
  }
}
@media (min-width: 530px) {
  #root > .content_card {
    width: 500px;
    background-color: $theme-background-color;
    box-shadow:
      0 4px 4px rgba(0, 23, 80, 0.01),
      0 1px 6px rgba(0, 23, 80, 0.015),
      0 8px 8px rgba(0, 23, 80, 0.012),
      0 16px 16px rgba(0, 23, 80, 0.012),
      8px 32px 32px rgba(0, 23, 80, 0.018),
      8px 64px 64px rgba(0, 23, 80, 0.018);
  }
  .slick-prev {
    left: 10px !important;
  }
  .slick-next {
    right: 10px !important;
  }
  .fortune__card__container .slider-container .slide__wrapper .description-wrapper {
    .description {
      p {
        max-width: 360px;
      }
    }
  }
}