/**  =====================
      Custom css start
==========================  **/
@font-face {
  font-family: 'GmarketSans';
  font-weight: 300;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.eot?#iefix') format('embedded-opentype'),
       url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.woff2') format('woff2'),
       url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.woff') format('woff'),
       url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansLight.ttf') format("truetype");
  font-display: swap;
} 
@font-face {
  font-family: 'GmarketSans';
  font-weight: 500;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.eot?#iefix') format('embedded-opentype'),
       url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.woff2') format('woff2'),
       url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.woff') format('woff'),
       url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansMedium.ttf') format("truetype");
  font-display: swap;
} 
@font-face {
  font-family: 'GmarketSans';
  font-weight: 700;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.eot?#iefix') format('embedded-opentype'),
       url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.woff2') format('woff2'),
       url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.woff') format('woff'),
       url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.ttf') format("truetype");
  font-display: swap;
}
body {
  font-family: 'NanumSquare', sans-serif;
  color: $text-color;
  margin: 0;
  padding: 0;
  background: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family:   'GmarketSans', sans-serif;
}

a {
  color: inherit;
  text-decoration: underline;
}

#root {
  display: flex;
  justify-content: center;
  padding-bottom: env(safe-area-inset-bottom);
}
#root > .content{
  width: -webkit-fill-available;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: $theme-background-color;
  min-height: 100vh;
}
@media (min-width: 530px) {
  #root > .content{
    width: 500px;
    background-color: $theme-background-color;
    box-shadow: 0 4px 4px rgba(0,23,80,.01),0 1px 6px rgba(0,23,80,.015),0 8px 8px rgba(0,23,80,.012),0 16px 16px rgba(0,23,80,.012),8px 32px 32px rgba(0,23,80,.018),8px 64px 64px rgba(0,23,80,.018);
  }
}
.btn-base {
  border-radius: 3px;
  background: $base-linear-gradient;
  color: white;
  border: none !important;
  height: 42px;
  &:hover,
  &:active,
  &:focus-visible,
  &:focus {
    border: none !important;
    border-radius: 3px;
    background: $base-linear-gradient;
    color: white !important;
  }
}


.btn-base-2 {
  border-radius: 3px;
  background: #575757;
  color: white;
  border: none !important;
  height: 42px;
  &:hover,
  &:active,
  &:focus-visible,
  &:focus {
    border: none !important;
    border-radius: 3px;
    background: #575757;
    color: white !important;
  }
}

.btn-base-3 {
  border-radius: 3px;
  background: #f8f8f8;
  color: #2a2b2b;
  border-radius: 3px;
  border: none !important;
  height: 42px;
  &:hover,
  &:active,
  &:focus-visible,
  &:focus {
    border: none !important;
    border-radius: 3px;
    background: #f8f8f8;
    color: #2a2b2b;
  }
}

.company__logo {
  width: 40px;
  height: 40px;
}

.btn-base-outline {
  color: $theme-base-color-1;
  background: white;
  height: 42px;
  position: relative;
  display: inline-block;
  // background: $base-linear-gradient;
  background: $text-color-2;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
  &:hover,
  &:active,
  &:focus-visible,
  &:focus {
    color: $theme-base-color-1;
    background: white;
    height: 42px;
    position: relative;
    display: inline-block;
    background: $border-linear-gradient;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border: none;
  }
}

.btn-base-outline::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 3px;
  padding: 2px;
  background: $border-linear-gradient;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

/*----------- toggle-switch ----------*/

.toggle-switch {
  position: relative;
  height: 44px;
  width: 215px;
  background-color: white;
  border-radius: 8px;
  border: 0.5px solid #85858533;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 2px 0px 4px 0px;
  .btn {
    display: flex;
    flex: 1;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    padding: 10px;
    position: relative;
    z-index: 1;
    transition: left 0.3s ease-in-out;
    background-color: white;
    color: $theme-base-color-1 !important;
  }
  .active {
    background: $base-linear-gradient;
    color: white !important;
  }
}

.cursor-pointer{
  cursor: pointer !important;
}

.slick-disabled {
  display: none !important;
}

.slick-next{
  z-index: 2;
}

.slick-prev {
  z-index: 2;
}
.slick-prev:before, .slick-next:before {
  display: none;
}

.ml-auto{
  margin-left: auto !important;
}

.react-datepicker__tab-loop{
  z-index: 11;
}

// .react-datepicker__input-container{
//   height: 42px;
// }
.review-modal .modal-content{
  background: #ffffff;
  border-radius: 10px;
  width: 320px;
  margin: auto;
  height: 70%;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  .modal-dialog{
    margin-top: 60px !important;
  }
  .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    width: 320px;
    scrollbar-width: none;
    .imageModal{
      width: 310px;
      overflow-y: scroll;
      overflow-x: hidden;
      scrollbar-width: none;
      .responsive-image {
        width: 100%; // 너비를 100%로 설정하여 화면 크기에 맞게 조정
        width: 280px; // 최대 너비를 260px로 고정
        height: auto; // 이미지의 비율을 유지
        @media (max-width: 390px) {
          max-width: 230px; // 작은 화면에서는 최대 너비를 230px로 설정
        }
      }
    } 
  }
}
.coming-soon .modal-content{
  background: #ffffff;
  border-radius: 10px;
  width: 330px;
  margin: auto;
  height: 300px;
  .modal-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    h6{
      color: #000000;
      text-align: left;
      font-size: 16px;
      font-weight: 500;
    }
    p{
      color: #000000;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
.modal-backdrop{
  width: 100%;
  height: 100%;
}

.Toastify__close-button{
  display: none;
}


.react-datepicker-popper{
  margin-left: 40px;
}
.react-datepicker {
  font-size: 0.8rem; /* 전체 폰트 크기를 줄여서 달력 크기 축소 */
  width: 260px; /* 달력 전체 너비 조정 */
  margin-bottom: 10px;
}

.react-datepicker__month-container {
  width: 100%; /* 월 컨테이너 너비 설정 */
}

.react-datepicker__header {
  padding: 8px;
  background-color: white !important;
  button{
    background-color: white;
    color: #141414;
    font-size: 14px;
    padding: 10px;
  }
  select{
    height: 36px;
    border-radius: 10px;
    color: #141414;
    font-size: 14px;
  }
}
.react-datepicker__day--outside-month {
  color: #b9b9b9!important; /* 텍스트 색상을 연한 회색으로 변경 */
}
.react-datepicker__day-names {
  font-size: 0.8rem; /* 요일 폰트 크기 줄이기 */
}
.react-datepicker__aria-live{
  display: none;
}
.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0px 5px !important;
}
.react-datepicker__day {
  width: 2rem; /* 날짜 셀 너비 */
  height: 2rem; /* 날짜 셀 높이 */
  line-height: 2rem; /* 날짜 셀 텍스트 수직 정렬 */
  font-size: 0.8rem; /* 날짜 텍스트 크기 조정 */
  margin: 0.1rem; /* 셀 간격 조정 */
}

.react-datepicker__day--selected,
.react-datepicker__day--today {
  font-weight: bold;
  background-color: #6cb3ff !important; /* 선택된 날짜 배경색 */
  color: white;
  border-radius: 5px  !important;
  height: 27px !important;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  font-size: 0.8rem; /* 이전/다음 버튼 폰트 크기 조정 */
  padding: 0px;
  background: transparent;
}
.selectedBox{
  .right_button, .left_button{
    background-color: transparent;
    padding: 15px;
  }
  .right_button{
    text-align: right;
    justify-content: flex-end;
  }
}
.react-datepicker__year-select, 
.react-datepicker__month-select {
  max-height: 150px !important; /* 드롭다운 높이 제한 */
  overflow-y: auto; /* 스크롤 추가 */
}

.react-datepicker__year-select {
  width: 70px; /* 년도 선택 너비 */
}

.react-datepicker__month-select {
  width: 60px; /* 월 선택 너비 */
}
.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 10px 0px 10px 10px;
  width: 100px;
}
.input.MuiSelect-nativeInput.css-j0riat-MuiSelect-nativeInput{
  margin-right: 0px !important;
  width: 30px;
}


.MuiInputBase-input.MuiOutlinedInput-input{
  padding-right: 0px !important;
  width: 112px !important;
}
.modal-open{
  padding-right: 0px!important;
}